import Vue from "vue";
import Vuex from "vuex";
// cs-log 去除持久化缓存
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: "id",
    userInfo: null,
    preRouter: "",
    webpSupport: true,
    deviceId: "",
    trackParams: null,
    isMobile: false,
    isLogin: false, // 是否登录
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setLanguage(state, lang) {
      state.language = lang;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setPreRouter(state, router) {
      state.preRouter = router;
    },
    setWebpSupport(state, webpSupport) {
      state.webpSupport = webpSupport;
    },
    setTrackParams(state, trackParams) {
      state.trackParams = trackParams;
    },
    setLoginStatus(state, tokenVal) {
      state.isLogin = !!tokenVal;
    },
  },
  actions: {
    logUserOut({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
      commit("setUserInfo", null);
    },
  },
  // cs-log 去除持久化缓存，因为和commit配合使用取数据时，数据源不准确
  // plugins: [createPersistedState()],
});

export default store;
