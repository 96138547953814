import axios from "axios";
import Cookie from "vue-cookies";
import store from "../store";

const service = axios.create({
  baseURL:
    process.env.VUE_APP_ENV === "development"
      ? "/api"
      : process.env.VUE_APP_STORE_URL,
});

service.interceptors.request.use(
  (config) => {
    const cookie = Cookie.get("JSESSIONID");
    config.headers.cookies = cookie;
    const token = localStorage.getItem("storeHomeToken");
    // if (config.url === "/api/user/login" || config.url === "/api/user/token") {
    //   return config;
    // }
    if (token) {
      config.headers.Authentication = `Bearer ${token}`;
    } else if (store.state.userInfo) {
      store.dispatch("logUserOut");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.headers["new-token"]) {
      const newToken = response.headers["new-token"];
      localStorage.setItem("storeHomeToken", newToken);
    }
    return response;
  },
  (error) => {
    // cs-log 对401 和 403进行对比
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      store.dispatch("logUserOut");
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data === "JWT_TOKEN_CHECK_FAILED"
    ) {
      store.dispatch("logUserOut");
    }
    return Promise.reject(error);
  }
);

export default service;
