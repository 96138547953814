import { destyTracker } from "desty-tracker";

export default {
  install: (app) => {
    destyTracker.init({
      env: process.env.VUE_APP_ENV, // 环境标识
      businessName: "StoreHomepage", // 业务产线
      sessionIntercept: true, // 是否拦截session存活时间的自动上报
    });
    app.prototype.$logEvent = (eventName, eventObj = {}, isPv = false) => {
      window.gtag("event", eventName, eventObj);
      if (isPv) {
        destyTracker.pvLogEvent(eventName, eventObj);
      }
    };
  },
};
