import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Index"),
    children: [
      {
        name: "Home",
        path: "",
        component: () => import("../views/home/Index"),
      },
      {
        name: "Home",
        path: "/store",
        component: () => import("../views/home/Index"),
      },
      {
        name: "Gallery",
        path: "/gallery",
        component: () => import("../views/gallery/Index"),
      },
      // {
      //   name: "Pricing",
      //   path: "/pricing",
      //   component: () => import("../views/pricing/Index"),
      // },
      {
        name: "Templates",
        path: "/templates",
        component: () => import("../views/templates/Index"),
      },
      // {
      //   name: "StorePrinterous",
      //   path: "/storePrinterous",
      //   component: () => import("../views/DS_printerous/Index"),
      // },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
