const intersectionObserver = new IntersectionObserver(function (entries) {
  entries.forEach(function (entry) {
    if (entry.intersectionRatio > 0) {
      const obj = entry.target;
      const imgSrc = obj.getAttribute("lazyLoadSrc");
      obj.setAttribute("src", imgSrc);
      intersectionObserver.unobserve(entry.target);
    }
  });
});

export default intersectionObserver;
