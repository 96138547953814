import axios from "axios";

// 获取ipHome 因为和store homepage的baseURL不一致，所以这里单独处理
export function getIpHomeAction() {
  axios.defaults.baseURL =
    process.env.VUE_APP_ENV === "development"
      ? "/api"
      : process.env.VUE_APP_BASE_URL;
  return axios.post("/api/external/geolite/country");
}
