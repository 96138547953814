<template>
  <div>
    <router-view />
    <DestyTranslate ref="destyTranslateRef" :isProduction="isProduction" />
  </div>
</template>

<script>
import { DestyTranslate } from "desty-design";
import { getIpHomeAction } from "@/service";

export default {
  metaInfo() {
    return {
      title: this.$t("meta.title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.keywords"),
        },
        {
          name: "og:title",
          content: this.$t("meta.title"),
        },
        {
          name: "og:description",
          content: this.$t("meta.description"),
        },
        {
          name: "og:image",
          content:
            "https://static.desty.app/desty-store-homepage/home/storelogo.svg",
        },
        {
          name: "og:url",
          content: process.env.VUE_APP_BASE_URL,
        },
        {
          name: "twitter:title",
          content: this.$t("meta.title"),
        },
        {
          name: "twitter:description",
          content: this.$t("meta.description"),
        },
        {
          name: "twitter:image",
          content:
            "https://static.desty.app/desty-store-homepage/home/storelogo.svg",
        },
        {
          name: "twitter:url",
          content: process.env.VUE_APP_BASE_URL,
        },
      ],
    };
  },
  data() {
    return {
      isProduction: process.env.VUE_APP_ENV === "production",
    };
  },
  components: {
    DestyTranslate,
  },
  provide() {
    return {
      // 因为provide先于mounted执行 所以() => {}的形式让refs变成响应式
      destyTranslateRef: () => this.$refs.destyTranslateRef,
    };
  },
  mounted() {
    this.plantMatch();
    window.addEventListener("resize", this.plantMatch);
    this.setCommonLangAndLoginStatus();
  },
  methods: {
    // 平台判断
    plantMatch() {
      // 此处是768的原因：无头浏览器宽度小于960，会默认预缓存mobile，mobile有draw广告组件会默认展开，会给body加el-popup-parent--hidden，导致页面无法滚动
      if (document.body.clientWidth < 768) {
        this.$store.commit("setIsMobile", true);
      } else {
        this.$store.commit("setIsMobile", false);
      }
    },
    async getIpHome() {
      // 获取ip地址码
      const res = await getIpHomeAction();
      const { code, data = {} } = res?.data || {};
      if (code === "200") {
        return data?.countryIsoCode;
      }
    },
    async setCommonLangAndLoginStatus() {
      try {
        // 设置通用语言
        const currDestyTranslateRef = this.$refs.destyTranslateRef;
        const storageEnabled = await currDestyTranslateRef.isStorageEnabled();
        let commonLang = "id";
        if (storageEnabled) {
          commonLang =
            await currDestyTranslateRef.getCommonTranslateBySingleKey("lang");
        }
        if (!commonLang) {
          // 根据ipHome设置
          const locationIpHome = await this.getIpHome();
          commonLang = locationIpHome === "ID" ? "id" : "en";
        }
        if (storageEnabled) {
          currDestyTranslateRef.setCommonTranslate({
            dataKey: "lang",
            dataValue: commonLang,
          });
        }
        this.$store.commit("setLanguage", commonLang);
        this.$i18n.locale = commonLang;
      } catch (error) {
        this.$store.commit("setLanguage", "id");
        this.$i18n.locale = "id";
        console.log("setCommonTranslate error", error);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.plantMatch);
  },
};
</script>
<style lang="less">
// detsy-design style.css
@import "~desty-design/lib/style.css";
@import "./assets/styles/base";
@import "./assets/styles/font";
@import "./assets/styles/index";
</style>
