import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
// 引入Meta
import Meta from "vue-meta";
// axios 引入
import axios from "./http/request";
// SVG 引入
import "./svgIndex";
import dataReport from "./plugins/dataReport";
import intersectionObserver from "./utils/lazyLoad";
import { destyHandleCookie } from "desty-design";

const { handleGetSSOCookie } = destyHandleCookie;
const currToken = handleGetSSOCookie()?.["Store"];
store.commit("setLoginStatus", currToken || "");

Vue.config.productionTip = false;
Vue.use(Meta);
Vue.prototype.$axios = axios;
Vue.directive("lazyLoadImg", {
  bind: function (el) {
    intersectionObserver.observe(el);
  },
  unbind: function (el) {
    intersectionObserver.unobserve(el);
  },
});
// 跟踪ga/desty-tracker
Vue.use(dataReport);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
